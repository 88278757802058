import Image from "next/image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";

const NewCollectionBanner = (props: any) => {
  let logoImage: any = formatImageUrl(
    props?.data?.media?.data?.attributes?.url
  );
  let bottleImage: any = formatImageUrl(
    props?.data?.bottle_image?.data?.attributes?.url
  );
  let bgImage: any = formatImageUrl(props?.data?.banner?.data?.attributes?.url);
  return (
    <section
      className="new_octave_collection_section"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="collection_container">
        <div className="octave_collection_inner_div">
          <Row>
            <Col md={7}>
              <div className={`octave_banner_logo_section ${props?.className}`}>
                {props?.data?.media?.data && (
                  <img
                    src={logoImage}
                    alt={`octave logo`}
                    // width={228}
                    // height={290}
                    className={`collection_logo ${props?.className}`}
                  />
                )}

                <div className="inner_text_logo">
                  <h2>{props?.data?.description}</h2>
                  {props?.data?.description_bottom && (
                    <h2 className="pt-0">{props?.data?.description_bottom}</h2>
                  )}
                </div>
              </div>
            </Col>
            <Col md={5}>
              <div className="octave_product_img_section">
                {props?.data?.bottle_image?.data && (
                  <img
                    src={bottleImage}
                    alt={`octave logo`}
                    className={`${props?.mainBottleImg}`}
                    // width={336}
                    // height={679}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default NewCollectionBanner;
