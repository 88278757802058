"use client";
import Image from "next/image";
import React from "react";
import { Modal } from "react-bootstrap";
// import Modal from "react-bootstrap-modal";

const BuyNowCart = (props: any) => {
  const {
    openModal,
    toggleCartSidebar,
    checkout,
    updateQuantityInCart,
    removeLineItemInCart,
    countryCode,
  } = props;
  const onHideFunction = () => {
    console.log("ok");
  };

  const decrementQuantity = (lineItemId: any, line_item: any) => {
    const updatedQuantity = line_item.quantity - 1;
    updateQuantityInCart(lineItemId, updatedQuantity);
  };

  const incrementQuantity = (lineItemId: any, line_item: any) => {
    const updatedQuantity = line_item.quantity + 1;
    updateQuantityInCart(lineItemId, updatedQuantity);
  };

  const openCheckout = () => {
    window.open(checkout.webUrl, "_self");
  };

  console.log(checkout, "checkout");

  return (
    <Modal
      show={openModal}
      onHide={() => onHideFunction()}
      aria-labelledby="ModalHeader"
      className="buy_now_cart_modal"
    >
      <Modal.Header>
        <h2>Your cart</h2>
        <Image
          src="/images/close-icon.png"
          width={18}
          height={19}
          alt={`close icon`}
          onClick={() => toggleCartSidebar()}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="main_bottle_details">
          {checkout?.lineItems &&
            checkout?.lineItems?.map((line_item: any) => {
              return (
                <div className="cart_product_card" key={line_item.id}>
                  <div className="cart_product_image">
                    {line_item.variant.image && (
                      <img
                        src={line_item.variant.image.src}
                        width={79}
                        height={99}
                        alt={`product icon`}
                      />
                    )}
                  </div>
                  <div className="cart_product_details">
                    <h2>{line_item.title}</h2>
                    <h3>
                      {countryCode === "en-US" ? "$ " : "£ "}
                      {(
                        line_item.quantity * line_item.variant?.price?.amount
                      ).toFixed(2)}
                    </h3>
                    <div className="total_item_box">
                      <div
                        className="minus_item"
                        onClick={() =>
                          decrementQuantity(line_item.id, line_item)
                        }
                      >
                        -
                      </div>
                      <div className="total_item">{line_item.quantity}</div>
                      <div
                        className="plus_item"
                        onClick={() =>
                          incrementQuantity(line_item.id, line_item)
                        }
                      >
                        +
                      </div>
                    </div>
                    <div
                      className="remove_item"
                      onClick={() => removeLineItemInCart(line_item.id)}
                    >
                      REMOVE
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>

      <Modal.Footer>
        {checkout && checkout?.lineItems?.length > 0 && (
          <div className="cart_price_section">
            <div className="total_price_section">
              <h3>Subtotal(Ex. VAT price)</h3>
              <h4>
                {" "}
                {countryCode === "en-US" ? "$ " : "£ "}
                {checkout?.totalPrice?.amount}
              </h4>
            </div>
            <p>Shipping, taxes, and discount codes calculated at checkout.</p>

            <div className="btn_group_for_cart">
              <button className="btn_view_cart">VIEW CART</button>
              <button className="btn_checkout" onClick={openCheckout}>
                CHECKOUT
              </button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BuyNowCart;
